























































































































































































import ViewInfiniteCollection from "./booksCollection";
export default ViewInfiniteCollection;
