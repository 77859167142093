import Axios from 'axios';
import Vue from 'vue';
import Component from 'vue-class-component';
import Snackbars from '@/components/Snackbars.vue';
import { Watch } from 'vue-property-decorator';

@Component({
  components: {
    Snackbars
  }
})
export default class ViewInfiniteCollection extends Vue {
  searchData = '';
  benched = 0;
  scrollListHeight = 300;
  screenHeight = 0;
  drawer = true;
  selectedItemId = '';
  isLoading = false;
  dataLoading = false;
  valid = true;
  form = {
    id: -1,
    title: "",
    chapter: ""
  }
  allInfiniteLibData = [];
  filterList: any = [];
  operationHistoryRecord = [];
  historyRecordDialog = false;

  bookListSlideGroup = '';

  bookList = [];
  bookId = '';

  bookForm = {
    categoryId: 1,
    title: '',
    author: ''
  }
  dialogBookEdit = false;

  bookCategoryList = [];


  nameRules = [
    (v: any) => !!v || 'Name is required',
    (v: string | any[]) => (v && v.length <= 100) || 'Name must be less than 100 characters',
  ]

  describeRules = [
    (v: any) => !!v || 'Describe is required',
    (v: string | any[]) => (v && v.length <= 2000) || 'describe must be less than 2000 characters',
  ]

  mounted() {
    const _this = this;
    window.onresize = function () { // 定义窗口大小变更通知事件
      _this.screenHeight = document.documentElement.clientHeight; //窗口高度
    };
    _this.screenHeight = document.documentElement.clientHeight;
    this.findAllBooks();
    this.getBookCategory();
  }

  filterUrlData() {
    const _this = this;
    _this.filterList = [];
    _this.allInfiniteLibData.forEach(function (value: any) {
      if (new RegExp(_this.searchData, 'i').test(value.title)) {
        _this.filterList.push(value);
      }
    });
    return this.filterList;
  }

  @Watch("screenHeight")
  windowHeightAuto(val: number) {
    this.scrollListHeight = val - 210;
  }

  @Watch("selectedItemId")
  selectItem(val: number) {
    const _this = this;
    _this.$axios({
      url: '/api/v1/book/chapter/id',
      method: 'put',
      data: {
        id: val
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        const result = response.data.data;
        console.log(result);
        _this.form.id = response.data.data.id;
        _this.form.title = response.data.data.title;
        _this.form.chapter = response.data.data.chapter;
        _this.$toast.success(response.data.message);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  getOperationHistoryRecord() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/infinite/lib/record',
      method: 'post',
      data: {
        name: _this.form.id
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        const result = response.data.data;
        _this.operationHistoryRecord = result;
        _this.$toast.success(response.data.message);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });

  }


  deleteData() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/book/chapter/delete',
      method: 'delete',
      data: this.form
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.getBookChapter();
        (_this.$refs as any).form.reset()
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  commitForm() {
    const _this = this;
    (_this.$refs as any).form.validate();
    _this.$axios({
      url: '/api/v1/book/chapter/save',
      method: 'put',
      data: {
        bookId: _this.bookId,
        id: _this.form.id,
        title: _this.form.title,
        chapter: _this.form.chapter
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.$toast.success(response.data.message);
        _this.getBookChapter();
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  linkClick(item: any) {
    this.form = {
      id: item.id,
      title: item.title,
      chapter: item.chapter
    }
  }


  newForm() {
    this.form = {
      id: -1,
      title: '',
      chapter: ''
    }
  }

  findAllBooks() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/book/list',
      method: 'get'
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.bookList = response.data.data;
        _this.$toast.success(response.data.message);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  getBookChapter() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/book/chapter/title',
      method: 'put',
      data: {
        id: this.bookId
      }
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.allInfiniteLibData = response.data.data;
        _this.filterList = _this.allInfiniteLibData;
        _this.$toast.success(response.data.message);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  getBookCategory() {
    const _this = this;
    _this.$axios({
      url: '/api/v1/book/category/list',
      method: 'get'
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.bookCategoryList = response.data.data;
        _this.$toast.success(response.data.message);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

  saveBook() {
    const _this = this;
    console.log(this.bookForm);
    _this.$axios({
      url: '/api/v1/book/save',
      method: 'put',
      data: this.bookForm
    }).then(function (response) {
      if (response.data.code === 0) {
        _this.findAllBooks();
        _this.$toast.success(response.data.message);
      } else {
        _this.$toast.error(response.data.message);
      }
    }).catch(function (err) {
      _this.$toast.error(err);
    });
  }

}


